<template>
  <div id="mywrongtest">
    <div class="test-row" v-for="paper in testPapers" :key="paper.id" @click="goQuestion(paper)">
      <div class="title">{{paper.title}}</div>
      <div class="tag">
        <el-space>
          <el-tag type="primary">{{paper.type}}</el-tag>
          <el-tag type="warning">{{paper.spec}}</el-tag>
          <el-tag type="success">{{paper.course}}</el-tag>
        </el-space>
      </div>
      <el-divider border-style="dashed"></el-divider>
    </div>
  </div>
</template>

<script type="text/javascript">
import {getWrongTest} from '@/api/user'
import {setSessionStorage} from '@/utils/auth'
export default {
  data() {
    return {
      testPapers:[]
    }
  },
  created(){
    this.getMyWrongTest();
  },
  components: {

  },
  props:{

  },
  methods:{
    getMyWrongTest(){
      getWrongTest({flag:0}).then(
        res => {
          if(res.code == 1){
            this.testPapers = res.data;
          }
        }
      )
    },
    goQuestion(paper){
      // setSessionStorage("currentPaper",paper);
      // this.$router.push('/')
      if (paper.question_num == 0) {
        this.$message("当前试卷暂无试题");
        return;
      }
      // 试卷基本信息
      setSessionStorage("currentPaper", paper);
      window.open("/questionresult");
    }
  }
}
</script>

<style lang="scss" scoped>
#mywrongtest{
  .test-row{
    .title{
      font-size:16px;
      font-weight: bold;
      height:40px;
      line-height: 40px;
    }
    .tag{
      height:30px;
      line-height: 30px;
    }
  }
}
</style>