import request from '@/utils/request'
import qs from 'qs';

// 获取网站信息
export function getSiteInfo() {
  return request({
    url: '/index/siteinfo',
    method: 'post'
  })
}
//用户登录
export function userLogin(data) {
  return request(
    {
      url: '/login',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
//获取用户信息
export function getUserInfo() {
  return request(
    {
      url: '/user/userinfo',
      method: 'post',
    }
  )
}
//获取短信验证码
export function getSmsCheckCode(data) {
  return request({
    url: '/sms',
    method: 'post',
    data: qs.stringify(data)
  })
}

//用户注册
export function userRegister(data) {
  return request({
    url: '/register',
    method: 'post',
    data: qs.stringify(data)
  })
}

//修改密码
export function updatePassword(data) {
  return request({
    url: '/user/upUserPass',
    method: 'post',
    data: qs.stringify(data)
  })
}

//获取重置密码时的短信验证码
export function getResetSmsCheckCode(data) {
  return request({
    url: '/smsPass',
    method: 'post',
    data: qs.stringify(data)
  })
}

//重置密码，使用验证码
export function resetPassword(data) {
  return request({
    url: '/upPass',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 修改用户信息
export function updateUserInfo(data) {
  return request({
    url: '/user/upuserinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 我的课程
export function myCourse(data) {
  return request({
    url: '/myGrade',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取协议
export function getAgree(data) {
  return request({
    url: '/agree',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 签署协议
export function signAgree(data) {
  return request({
    url: '/signAgree',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询是否签署协议
export function checkAgree(data) {
  return request({
    url: '/checkAgree',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 某个套餐用户最后以及观看过的的班级和章节
export function seachPlayEndRecord(data) {
  return request({
    url: '/user/endHistory',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询课程提问
export function searchQuestion(data) {
  return request({
    url: '/questionByGrade',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询课程评价
export function searchComment(data) {
  return request({
    url: '/commentByGrade',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 班级
export function getCourseSection(data) {
  return request({
    url: '/product/section',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 套餐
export function getCourseSingle(data) {
  return request({
    url: '/product/single',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 取消订单
export function removeOrder(data) {
  return request({
    url: '/removeOrder',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 支付宝
export function aliPay(data) {
  return request({
    url: '/AliPay/Native',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 微信
export function wxPay(data) {
  return request({
    url: '/Weixin/Native',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 微信是否支付成功
export function wxPayState(data) {
  return request({
    url: '/Weixin/notify',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 我的订单,所有
export function myOrderAll(data) {
  return request({
    url: '/myOrderAll',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 商户信息
export function getMerInfo(data) {
  return request({
    url: '/merInfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
//支付宝是否支付成功
export function aliPayCom(data) {
  return request({
    url: '/AliPay/notify',
    method: 'post',
    data: qs.stringify(data)
  })
}
//获取验证码登录的验证码
export function getLoginSmsCheckCode(data) {
  return request({
    url: '/smsPass',
    method: 'post',
    data: qs.stringify(data)
  })
}
//用户用验证码登录
export function loginBySms(data) {
  return request({
    url: '/loginByPhoneAndCode',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取课程观看记录
export function getCourseHistory(data) {
  return request({
    url: '/user/secHistory',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 用户提问
export function reQuestion(data) {
  return request({
    url: '/reQuestion',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 用户提问列表
export function myQuestion(data) {
  return request({
    url: '/myQuestion',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 收藏的课程列表
export function getCollCombo(data) {
  return request({
    url: '/user/getCollCombo',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 收藏的试卷列表
export function getCollPaper(data) {
  return request({
    url: '/user/myColl',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 收藏的试题列表
export function getCollTest(data) {
  return request({
    url: '/user/myCollTests',
    method: 'post',
    data: qs.stringify(data)
  })
}
//错题试题列表
export function getWrongTest(data) {
  return request({
    url: '/user/getSignTest',
    method: 'post',
    data: qs.stringify(data)
  })
}